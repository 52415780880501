import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Payment Success" />
      <div className="container" style={{ flex: 1 }}>
        <h1>Thank you for your payment</h1>

        <p>We have successfully received your funds.</p>
      </div>
    </Layout>
  )
}

export default IndexPage
